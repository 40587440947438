@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600;900&display=swap');

* {
  background: transparent;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 0 none;
}

html {
  font-size: 18px;
}
body {
	font-family: Nunito, sans-serif;
    line-height: 1.6rem;
	background: #f4f5f0;
	color: #444;
}

h2 {
	font-weight: 900;
	margin: 30px 0 9px;
	font-size: 21px;
}
h2 span {
	display: inline-block;
	padding: 0 12px;
	background: #fff;
	color: #222;
	border-radius: 2px;
	line-height: 2;
}
.relative-time {
	margin-top: 15px;
}

h3 {
	margin: 15px 0 3px;
	font-weight: 400;
}

p {
	margin: 12px 0;
}
ul,
ol {
	margin: 0 0 15px 30px;
}
ul {
	list-style: circle;
}
a {
	display: inline-block;
	padding: 0 6px;
	border-radius: 3px;
	color: #444;
}
/*a:hover {
	text-decoration: none;
	background-color: #fff;
}*/


a:focus,
a:active {
	text-decoration: none;
}
a:active {

}

/* ---- */
input,
textarea,
select,
button {
	font-size: 1rem;
  	line-height: 1.6rem;
  	font-family: Nunito, sans-serif
}
input,
textarea {
  border: 3px solid #444;
  background: #fff;
  padding: 2px 12px;
  border-radius: 18px;
  font-size: 18px;
}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
	appearance: none;
}

button,
a.button {
  display: inline-block;
  padding: 0 15px;
  line-height: 30px;
  font-family: Nunito, sans-serif;
  background: #444;
  color: #fff;
  border: 3px solid #444;
  border-radius: 21px;
  font-size: 18px;
  font-weight: 900;
  user-select: none;
  text-decoration: none;
}
button.alt {
  background: #fff;
  color: #444;
  border: 1px solid #fff;
}
#gmap button {
	border-radius: inherit;
}
button.close {
	margin: 9px 0;
	font-size: 18px;
	line-height: 30px;
}

input + button {
	margin-left: 3px;
}

#header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	background: #fff;
}
#title {
	position: fixed;
	top: 0;
	left: calc(50% - 24px);
	width: 48px;
	padding: 3px 0;
	z-index: 6;
	text-align: center;
	animation: all .9s;
}
#title a {
	display: inline-block;
	padding: 0;
	background: #fff url(/logo.svg) no-repeat 50% 50%;
	background-size: 40px;
	line-height: 46px;
	text-decoration: none;
	color: #222;
	text-indent: -1000em;
	box-shadow: 0 0 9px rgba(0,0,0, .3);
	background-position: 50%;
	border-radius: 50%;
	width: 46px;
	animation: all .12s;
}


#e404 #content h1 {
	margin: 0 0 15px;
}
#e404 #content p {
	color: #666;
	font-size: 14px;
}

.current-location {
	display: block;
	text-align: center;
}
#current {
	margin-top: 9px;
	margin-left: 47px;
	text-align: left;
	font-size: 12px;
}
#current span {
	display: inline-block;
	padding: 0 9px;
	border-radius: 0;
	color: #666;
}
#current .hashtags {
	padding: 0 9px 0 0;
}
#current .hashtags:before {
	content: '•';
	display: inline-block;
	padding: 0 9px 0 0;
}


#index {
	position: fixed;
	min-height: 100vh;
	width: 100%;
	background: #f4f5f0;
	overflow: hidden;
}

#root > .rules {
	padding: 0 6px 18px;
	position: relative;
	text-align: center;
	overflow: hidden;
}

#root > .rules #title {
	position: absolute;
	top: 3px;
	left: calc(50% - 22.5px) !important;
}
#root > .rules #title a {
	line-height: 45px;
	width: 45px;
	background-size: 39px;
	text-indent: -3000em;
	color: #fff;
	font-size: 18px;
	text-shadow: 0 0 12px rgba(0,0,0,.6);
}


#index .to-posts {
	display: inline-block;
	background: #112c4e;
	color: #fff;
	padding: 2px 15px;
	text-decoration: none;
	border-radius: 15px;
	font-size: 13px;
}
#menu,
#back {
  position: fixed;
  top: 6px;
  right: 6px;
  height: 30px;
  width: 30px;
  line-height: 2rem;
  z-index: 5;
}
#back {
	background: #fff url(/icons/icon-back.svg) 50% 50% no-repeat;
	background-size: 24px;
}
#content {
	position: relative;
	padding: 6px 0 180px;
	margin: 45px 6px 0;
	max-width: 630px;
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
}
#content:empty {
	padding: 0;
}
#index #content {
	padding: 0;
	margin: 0;
	max-width: 100%;
}

#index #content .location {
	position: fixed;
	top: 42px;
	left: calc(50% - 110px);
	width: 220px;
	margin: 0;
	padding: 0;
	font-size: 12px;
	text-align: center;
}
#index #content .location span {
	display: inline-block;
	padding: 0 9px;
	background: #fff;
	box-shadow: 0 0 3px rgba(0, 0, 0, .2);
	border-radius: 12px;
}
#content .status {
	position: fixed;
	top: 45px;
	left: calc(50% - 135px);
	width: 270px;
	margin: 0;
	padding: 0;
	text-align: center;
}

#e404 #content {
	margin-top: 120px;
	text-align: center;
}

#content .to-messages {
	text-decoration: none;
	color: #fff;
}

#content h1 {
	margin: 30px 6px 15px;
	font-weight: 900;
	text-align: center;
}
#content .descr {
	margin: 15px 0;
}

#to-feed.active,
#to-map.active,
#add-new.active  {
	background-color: #f7f7f7;
}

#add-new.active:after {
	background: #fff url(/icons/icon-add-alt.svg) 50% 50% no-repeat;
	background-size: 24px;
}
.tip,
.status {
	display: block;
	background: #fff;
	text-align: center;
	font-size: 12px;
	padding: 3px 9px 3px;
	margin-top: 0;
}
.status {
	background: transparent;
}

#auth {
	max-width: 480px;
	margin: 0 auto;
}
#auth form {
	margin: 45px 0 0;
}

#logout {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

.icon {
	display: inline-block;
}
.icon.marker-center {
	padding-left: 21px;
	background: transparent url(/icons/marker-center.svg) 3px 50% no-repeat;
	background-size: 12px;
}
.icon.marker-location {
	padding-left: 15px;
	background: transparent url(/icons/icon-marker.svg) 0 50% no-repeat;
	background-size: 9px;
}
.icon.marker-post {
	padding-left: 20px;
	background: transparent url(/icons/marker-post.svg) 0 50% no-repeat;
	background-size: 16px;
}
.icon.icon-position {
	padding-left: 24px;
	background: transparent url(/icons/icon-locate.svg) 3px 50% no-repeat;
	background-size: 16px;
}
.icon.icon-map {
	padding-left: 30px;
	background: transparent url(/icons/icon-marker.svg) 3px 50% no-repeat;
	background-size: 14px;
}
.icon.icon-list {
	padding-left: 30px;
	background: transparent url(/icons/icon-feed.svg) 3px 50% no-repeat;
	background-size: 14px;
}
.icon.icon-add {
	padding-left: 18px;
	background: transparent url(/icons/icon-add.svg) 3px 50% no-repeat;
	background-size: 10px;
}
.icon.icon-hashtags {
	padding-left: 30px;
	background: transparent url(/icons/icon-hashtag.svg) 3px 50% no-repeat;
	background-size: 14px;
}
.icon.icon-refresh {
	padding-left: 30px;
	background: transparent url(/icons/icon-refresh-2.svg) 3px 50% no-repeat;
	background-size: 24px;
}
.icon.icon-plane {
	padding-left: 21px;
	background: transparent url(/icons/icon-plane.svg) 3px 50% no-repeat;
	background-size: 15px;
}
.label .icon.icon-refresh {
	padding-left: 30px;
	background-size: 18px;
}

.guide.alt .label .icon.icon-refresh {
	padding-left: 24px;
}




#actions {
	position: fixed;
	bottom: 60px;
	left: calc(50% - 105px);
	z-index: 2;
	background-color: #132c4d;
	width: 210px;
	border-radius: 25.5px;
	box-shadow: 0 0 3px rgba(0, 0, 0, .3);
	text-align: center;
	padding: 3px;
	transition: bottom 0.5s ease;
}
#actions a:focus,
#actions button:focus {
	box-shadow: none;
}
#actions a:active,
#actions button:active {
/*	top: 0; */
}
.map-hybrid #actions {
	background-color: #fff;
}
#posts #actions  {
	bottom: 15px;
/*	width: 100%;
	bottom: 0;
	left: 0;
	border-radius: 0;*/
}
#message-counter {
	position: absolute;
	top: -18px;
	left: calc(50% - 69px);
	width: 42px;
	padding: 0;
	font-size: 15px;
	font-weight: 900;
	text-decoration: none;
	text-align: center;
}
#message-counter span {
	display: inline-block;
	background: #c32500;
	padding: 6px 7.5px;
	line-height: 12px;
	color: #fff;
	border-radius: 15px;
	box-shadow: 0 0 12px rgba(0, 0, 0, .3);
}
#add-new, 
#refresh,
#toggle-hashtags,
#to-map,
#to-feed {
	position: relative;
	width: 45px;
	height: 45px;
	line-height: 24px;
	display: inline-block;
	padding: 0;
	margin: 0;
	color: #fff;
	font-size: 13px;
	text-decoration: none;
	border-radius: 22.5px;
	text-indent: -1000em;
	border: 0 none;
	background: transparent;
	user-select: none;
	vertical-align: middle;
}

#to-map,
#to-feed {
	margin-left: 0;
}

#add-new {
	
}
#posts #add-new {

}

#add-new:after, 
#refresh:after,
#toggle-hashtags:after,
#to-feed:after,
#to-map:after {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	content:  "";
	width: 100%;
	background: transparent url(/icons/icon-feed-alt.svg) 50% 50% no-repeat;
	background-size: 27px;
	overflow: hidden;
	border-radius: 22.5px;
	user-select: none;
}
.map-hybrid #to-feed:after {
	background: transparent url(/icons/icon-feed.svg) 50% 50% no-repeat;
	background-size: 27px;

}

#add-new:after {
	background-image: url(/icons/icon-add-alt.svg);
	background-size: 24px;
	background-color: transparent;
}
.map-hybrid #add-new:after {
	background-image: url(/icons/icon-add.svg);
}

#refresh {
	text-indent: 0;
	text-align: center;
	font-size: 15px;
	text-transform: uppercase;
	width: 69px;
	height: 69px;
	margin: -12px 18px;
	background-color: #c32500;
	border: 3px solid #132c4d;
	border-radius: 50%;
}
.map-hybrid #refresh {
	border-color: #fff;
}

#refresh:after {
	border-radius: inherit;
	background-image: none;
}
#refresh.idle:after {
	background:  none;
	background-size: 36px;
}

@keyframes spin { 
	100% { 
		transform: rotate(360deg);
	}
}

#refresh.active {
	text-indent: -10000em;
}
#to-feed.active,
#to-map.active {
	border: 3px solid #132c4d;
}
#refresh.active:after {
	background-image: url(/icons/icon-refresh.svg);
	background-size: 45px;
	animation: spin 1s linear infinite;
}
#to-feed.active:after {
	background-image: url(/icons/icon-feed.svg);
	background-size: 24px;
}
#toggle-hashtags.filter-on:before {
	content: '';
	position: absolute;
	width: 6px;
	height: 6px;
	top: 3px;
	right: 6px;
	background-color: #c32500;
	border-radius: 6px;
}
#toggle-hashtags {
	border: 3px solid #132c4d;
}
#toggle-hashtags:after {
	background-image: url(/icons/icon-hashtag.svg);
	background-size: 24px;
}
#toggle-hashtags.active:before {
	display: none;
}
#toggle-hashtags.active:after {
	background-image: url(/icons/icon-hashtag-alt.svg);
	background-size: 24px;
	background-color: #fff;
}
#toggle-hashtags.inactive:after {
	background-image: url(/icons/icon-hashtag.svg);
	background-size: 24px;
	border:  none;
	opacity: .5;
}
#to-map:after {
	background-image: url(/icons/icon-marker.svg);
	background-size: 21px;
}
#to-map.active:after {
	background-image: url(/icons/icon-marker-alt.svg);
	background-size: 21px;
}
#error {
	margin: 15px 0;
	text-align: center;
}
#error.no-conn {
	position: fixed;
	top: calc(50% - 75px);
	left: calc(50% - 140px);
	z-index: 1;
}
#error.no-conn .message {
	background: #444;
	color:  #fff;
}
#error .message {
	padding: 6px 12px;
	display: block;
	font-size: 15px;
}
#error h2 {
	margin: 9px 6px;
	font-size: 15px;
	font-weight: 900;
	padding: 0 6px;
	border-radius: 3px;
}
#error p {
	font-size: 15px;
}
#error .hint {
	font-size: 12px;
	color: #666;
}

#error.autoclose {
	position: fixed;
	top: auto;
	bottom: calc(150px);
	left: 60px;
	width: calc(100% - 120px);
	z-index: 4;
	font-size: 12px;
}

#error .actions {
	margin-top: 3px;
}
#error .button {
	display: inline-block;
	padding: 0 12px;
	border-radius: 15px;
	background: #444;
	color: #fff;
	text-decoration: none;
	font-weight: 900;
	border: 0 none;
}
#error .button.add {
	background-color: #69791a;
	color: #fff;
}
#error .button.alt {
	background: transparent;
	color: #444;
}
#error a {
	background: transparent;
	display: inline-block;
	padding: 1px 6px;
	border-radius: 3px;
}

#error.autoclose .message {
	background: transparent;
	box-shadow: none;
	animation: messageAnimation 6s ease-in-out;
}
#error.no-post.autoclose.status .message {
	animation: messageAnimation 6s ease-in-out;
}

#alert.autoclose {
	animation: messageAnimation 6s ease-in-out;
}



#error.autoclose.status .message h2,
#error.autoclose.status .message p {
	margin: 0;
	line-height: 24px;
	background: #444;
	color: #fff;
	box-shadow: 0 0 30px rgba(0, 0, 0, .3);
	border-radius: 3px;
	padding: 3px 12px;
	display: inline-block;
	font-weight: 900;
}

#error.autoclose.status .message h2 + p,
#error.autoclose.status .message p + p {
	margin-top: 6px;
}
#error.autoclose.status .message .hint {
	display: inline-block;
	line-height: 24px;
	color: #666;
	background: #fff;
	color: #444;
	border-radius: 14px;
}

@keyframes messageAnimation {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    9% {
        opacity: 1;
        transform: translateY(0);
    }
    15% {
        transform: translateY(-6px);
    }
    30% {
        transform: translateY(0px);
    }
    45% {
        transform: translateY(-9px);
    }
    60% {
        transform: translateY(0px);
    }
    75% {
        transform: translateY(-6px);
    }
    
    90% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

#search {
	position: fixed;
	top: 0;
	left: 0;
	width: calc(100% - 12px);
	max-height: calc(100vh - 63px);
	overflow: auto;
	display: block;
	text-align: center;
	margin: 0 6px 0;
	padding: 63px 0 12px;
	list-style: none;
	z-index: 3;
}

#posts #search {
	position: sticky;
	background: #f4f5f0;
	background: linear-gradient(to bottom, rgba(244, 245, 240, 1) 90%, rgba(244, 245, 240, 0) 100%);
}

#search .filter-off {
	position: relative;
	background: #132c4d;
	border-color: #132c4d;
	font-size: 15px;
	line-height: 28px;
	box-shadow: 0 0 9px rgba(0, 0, 0, .3);
}
#search .filter-off.filtered {
	padding-right: 27px;
}
#search .filter-off.filtered:after {
	content: 'ON';
	position: absolute;
	top: 5px;
	right: 3px;
	height: 18px;
	width: 18px;
	background: #fff;
	border-radius: 50%;
	font-size: 9px;
	line-height: 18px;
	color: #444;
}
.map-hybrid #search .filter-off {
	background: #fff;
	color: #132c4d;
	border-color: #fff;

}



#cookie-notif {
	margin: 69px auto 0;
	width: calc(100% - 12px);
	text-align: center;
	font-size: 18px;
	z-index: 4;
	color: #444;
	padding: 0 6px 18px;
}
#cookie-notif h1 {
	margin: 6px 0;
	font-size: 27px;
	font-weight: normal;
	font-weight: 900;
}
#cookie-notif .step {
	font-weight: 900;
}
#cookie-notif .policy {
	margin: 45px 0;
}
#cookie-notif .policy a,
#rules .rules a {
	font-weight: 900;
	background: none;
	border: none;
	color: #444;
	padding: 0;
}
#cookie-notif .exit,
#cookie-notif .continue {
	display: inline-block;
	line-height: 36px;
	margin-right: 6px;
	background: #444;
	border: none;
	color: #fff;
	text-decoration: none;
	border-radius: 18px;
	padding: 0 15px;
	font-weight: 900;
	font-size: 18px;
	margin: 3px;
}
#cookie-notif .continue {
	background: #c32500;
	border: none;
}
#cookie-notif .actions {
	margin: 60px 0 0;
}

#alert {
	position: fixed;
	top: calc(50% - 45px);
	left: calc(50% - (140px - 6px));
	width: calc(280px - 12px);
	text-align: center;
	z-index: 4;
	background:  #fff;
	color: #444;
	border-radius: 3px;
	box-shadow: 0 0 30px #00000075;
}

#alert.autoclose {
	
}
#alert p {
	
}
#at #error,
#add-location {
	position: fixed;
	top: auto;
	bottom: 120px;
	left: calc(50% - calc(280px - 12px));
	width: calc(280px - 12px);
	z-index: 4;
}

/*#index #error .message,
#at #error .message,
#add-location .message,
#post .message,
#posts .message*/ 
#error .message {
	border-radius: 3px;
	background: #fff;
	box-shadow: 0 0 9px rgba(0, 0, 0, .3);
}

#index #error h2,
#at #error h2,
#posts .message h2 {
	font-size: 18px;
}
#index #error p,
#at #error p,
#posts .message p {
	font-size: 18px;
}

#footer:before {
  content: '';
  display: table;
  clear: both;
}
#footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  text-align: center;
  font-size: .875rem;
}

.response-message {
	text-align: center;
}

p.error {
	text-align: center;
	padding: 6px 12px;
	color: #c32500;
	font-weight: 900;
}
dl.error {
  margin: 15px 0;
  padding: 15px 0;
  border-bottom: 1px solid #c32500;
  text-align: center;
}
dl.error dt {
  font-size: 1.2rem;
}

.form {
	margin: 0;
}
.form-action {
	margin-top: 15px;
	text-align: center;
}
.form-action button,
.form-action a {
	display: inline-block;
	margin: 6px 6px 0;
}
.form-action button[type="submit"] {
	background: #444;
	color: #fff;
	border: none;
	border-radius: 18px;
	line-height: 30px;
	box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}
.input {
  display: block;
}
.input input,
.input textarea,
.input select {
	width: 100%;
}
.input label {
  display: block;
  padding: 0 12px;
}
.input input[type="checkbox"],
.input input[type="radio"] {
	display: inline-block;
	width: auto;
	margin: 0 6px 0 0;
}
.input label .hint {
	color: #666;
	
	line-height: 18px;
}
.input + .input {
  margin-top: 9px;
}
.input.error input {
	border-color: red;
}
.input.error .error-message {
	color: red;
}

.tips {
	list-style: none;
	max-width: 390px;
	margin:  0 auto;
	color: #777;
	text-align: center;
}
.tips li {
	font-size: 13px;
	line-height: 1.2;
}
.tips li + li {
	margin-top: 5px;
}

.tips a {
	color: #777;
}

.alt-link {
	margin: 0 0 15px;
	text-align: center;
}
.alt-link a {
	background-color: #d1d3cb;
	text-decoration: none;
	padding: 3px 15px;
	border-radius: 15px;
}
.response-text {
	width: text-align;
}
.actions {
	margin: 0;
	text-align: center;
}
#logout {
	text-align: center;
}
#login #actions,
#logout #actions {
	left: calc(50% - 120px);
}
#close {
	position: fixed;
	top: 6px;
	right: 9px;
	line-height: 34px;
	width: 40px;
	padding: 0;
	background: #fff;
	color: #444;
	text-decoration: none;
	text-align: left;
	text-indent: -1000em;
	background: #fff url(/icons/icon-back.svg) 50% 50% no-repeat;
	background-size: 20px;
	z-index: 2;
	border-radius: 20px;
	border:  3px solid #fff;
}
#back-step {
	position: fixed;
	top: 6px;
	left: 6px;
	line-height: 34px;
	width: 40px;
	padding: 0;
	background: #fff;
	color: #444;
	text-decoration: none;
	text-align: left;
	text-indent: -1000em;
	background: transparent url(/icons/icon-back.svg) 50% 50% no-repeat;
	background-size: 10px;
	z-index: 2;
	border-radius: 20px;
	border:  3px solid #fff;
}
.location #back-step {
	background-color: #fff;
}
#back-step.tofeed {
	background-image:  url(/icons/icon-back.svg);
	background-size: 10px;
}
#privacy #back-step,
#terms #back-step,
#cookies #back-step,
#register #back-step,
#user #back-step,
#login #back-step,
#logout #back-step,
#rules #back-step,
#post #back-step,
#e404 #back-step {
	right: 6px;
}

.cta {
	display: block;
	margin: 15px 0 15px;
	line-height: 2;

}
.cta a {
	background-color: #f4f5f0 !important;
	color: #444;
	padding: 0 12px !important;
	text-decoration: none;
	border-radius: 15px !important;
	line-height: 26px;
	font-size: 14px;
	
}
#settings h2 {
	text-align: center;
}

#dialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(255,255,255,.75);
	text-align: center;
	z-index: 2;
}
#dialog .check {
	width: 160px;
	position: absolute;
	top: calc(50% - 18px);
	left: calc(50% - 80px);
	line-height: 30px;
    background: #c32500;
    color: #fff;
    text-decoration: none;
    border: 3px solid #fff;
	border-radius: 21px;
	padding: 0 9px;
	box-shadow: 0 0 3px rgba(0,0,0,.2);
}
#dialog .cancel {
	width: 90px;
	position: absolute;
	top: calc(50% + 30px);
	left: calc(50% - 45px);
	line-height: 30px;
    background: #fff;
    color: #444;
    text-decoration: none;
    border: none;
	border-radius: 21px;
	padding: 0 9px;
	box-shadow: 0 0 3px rgba(0,0,0,.2);
}
#dialog ul {
	margin: 50vh 0 0;
}
#dialog li + li {
	margin-top: 6px;
}
#dialog a {
	display: inline-block;
	line-height: 30px;
	padding: 0 15px;
	background: #c32500;
	border-radius: 18px;
	text-decoration: none;
	border: 3px solid #fff;
	color: #fff;
	box-shadow: 0 0 3px rgba(0,0,0,.2);
}
#dialog a.check {

}
#dialog a.add {
	background: #693;
}



#post .footer .profile dt.name:after,
#reports .footer .profile dt.name:after,
#posts .footer .profile .name,
#add-post #contact .cname {
	background-color: #91A23A;
}
#post .footer .profile dt.description:after,
#reports .footer .profile dt.description:after,
#posts .footer .profile .description,
#add-post #contact .cinfo {
	background-color: #D45F35;
}
#post .footer .profile dd.phone a,
#reports .footer .profile dd.phone a,
#posts .footer .profile .phone,
#add-post #contact .cphone {
	background-color: #444;
}
#post .footer .profile dd.url a,
#reports .footer .profile dd.url a,
#posts .footer .profile .url,
#add-post #contact .curl {
	background-color: #356679;
}
#post .footer .profile dd.email a,
#reports .footer .profile dd.email a,
#posts .footer .profile .email,
#add-post #contact .cmail {
	background-color: #2C98B3;
}

@media screen and (min-width: 480px) {
	#error.autoclose {
		width: 420px;
		left: calc(50% - 210px);
	}
}

@media screen and (min-width: 640px) {
	
	#content {
		position: relative;
		margin: 30px auto 0;
	}
	
	#index #content.loaded {
		left: auto;
		right: 0;
		bottom: 0;
		width: calc(60vw - 6px);
	}
	#cookie-notif {
		left: calc(50% - (320px));
		width: 630px;
	}
	
}
@media screen and (min-width: 1024px) {

	.menu-on #title {
		margin: 0 auto !important;
	}
	
	#index #current {
		display: none;
		position: fixed;
		bottom: 66px;
		left: calc(100% - 640px - 24px);
		width:  640px;
		text-align: center;
		margin: 0;
	}
	#index.with-posts #current {
		display: block;
	}
}
@media screen and (min-width: 1200px) {
	
	#index.with-posts #content {
		left: calc(((90% - 640px) / 2) - 151.5px);
	}
	#index #current {
		left: calc(90% - 640px);
	}


	#post #content {
	
	}
}
@media screen and (min-width: 1400px) {
	
	#index.with-posts #content {
		left: calc(((60% - 320px) / 2) - 151.5px);
	}



	#post #content {
		
	}
	#index #current {
		left: calc(40%);
	}
}

#loading {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(255,255,255, 1);
	text-align: center;
	color: #444;
	z-index: 3;
	line-height: 24px;
	padding: 6px 0 0;
}
#loading .message {
	position: absolute;
	top: 120px;
	left: calc(50% - 120px);
	line-height: 24px;
	width: 240px;
	text-align: center;
}

.alert {
	position: fixed;
	right: 0;
	width: 240px;
	left: calc(50% - 120px);
	z-index: 8;
	top: calc(50% - 90px);
	background: #fff;
	border-radius: 3px;
	text-align: center;
	box-shadow: 0 0 90px rgba(0,0,0, .3);
}
.alert h1 {
	font-size: 18px;
	font-weight: normal;
	padding: 18px 6px 3px;
}
.alert .hint {
	display: block;
	font-size: 14px;
	padding: 3px 6px 12px;
}

#loading .message h2,
.alert h2 {
	margin: 9px 12px 6px;
}
.alert h2 .hint {
	display: block;
	font-size: 13px;
}
#loading .message p,
.alert p {
	line-height: 1.5;
	margin: 9px 6px;
}
.alert button {
	margin: 9px 3px;
}

.browse-status,
.guide,
.info {
	position: fixed;
	top: calc(50vh - 90px);
	width: 270px;
	left: calc(50% - 135px);
	background: #132c4d;
	color: #fff;;
	text-align: center;
	border-radius: 3px;
	box-shadow: 0 0 3px rgba(0,0,0, .3);
	z-index: 3;
}
.browse-status h2,
.guide h2,
.info h2 {
	margin: 0;
	color: #fff;
	margin: 18px 0 0;
	padding: 3px 6px;
	font-weight: 900;
	font-size: 27px;
}
.browse-status h2 {
	display: inline-block;
	font-size: 18px;
	line-height: 1.4;
	background: transparent;
}
.guide {
	box-shadow: 0 0 24px rgba(0,0,0, .3);
	padding: 0 12px;
}

.guide h2 {
	line-height: 1.1;
	background: transparent;
}
.guide h3 {
	margin: 15px 0;
	font-weight: 900;
	font-size: 21px;
}
.guide p {
	line-height: 1.5;
	font-weight: 900;
}
.guide ul {
	font-size: 13px;
	margin: 6px;
	text-align: left;
}
.guide button {
	background: #fff;
	color: #444;
	border-color: #fff;
}
.guide .hint {
	font-size: 12px;
}
.guide.alt {
	top: auto;
	left: calc(50% - (240px / 2));
	width: 240px;
	bottom: 154px;
}
.guide.alt:after {
	position: absolute;
	bottom: -24px;
	left: calc(50% - (24px / 2));
	content: '';
	height: 0;
	width: 0;
	border: 12px solid #fff;
	border-color: #132c4d transparent transparent;
}
.guide.alt li {
	padding: 6px 12px;
}
.guide.alt .label {
	text-align: center;
}
.browse-status {
	top: 63px;
	background: transparent;
	box-shadow: none;
}
.browse-status button {
	font-size: 24px;
	background: #132c4d;
	color: #fff;
	border-color: #132c4d;
	box-shadow: 0 0 24px rgba(0,0,0, .3);
}
.browse-status .hint {
	font-size: 13px;
	background: transparent;
}

.guide button.close {
	margin: 12px 3px 18px;
}
.guide h2 .hint {
	display: block;
	font-size: 15px;
	color: #666;
	background: transparent;
}
.info p {
	padding: 0 12px;
	font-size: 15px;
}
.info .hint {
	padding: 0 6px;
	font-size: 13px;
}

.intro {
	position: fixed;
	top: 45px;
	width: 280px;
	left: calc(50% - 140px);
	background: #fff;
	border-radius:  3px;
	text-align: center;
	box-shadow: 0 0 36px rgba(0, 0, 0, .2);
	z-index: 3;
}
#posts .intro {
	left: calc(50% - 140px);
}
.intro p {
	padding: 3px 12px 0;
}
.intro h2 {
	margin: 0;
	padding: 18px 12px 6px;
}
.intro img.description {
	display: block;
	margin: 30px auto 15px;
	width: 120px;
}
.intro .hint {
	display: block;
	font-size: 12px;
	line-height: 1.8;
	color: #666;
	margin: 6px 0;
}
.intro button.close {
	margin-bottom: 15px;
}

#at .guide {
	left: calc(50% - 140px);
}

@media screen and (min-width: 360px) {
	
	#error.autoclose {
		display: inline-block;

	}
}
#terms,
#privacy,
#cookies {
	max-width: calc(100% - 12px);
	margin: 0 auto;
	padding: 75px 0 18px;
}
#terms h1,
#privacy h1,
#cookies h1 {
	text-align: center;
}
#terms .meta,
#privacy .meta,
#cookies .meta {
	text-align: center;
	margin: 21px 0 36px;
	font-weight: 900;
}

@media screen and (min-width: 1024px) {
	
	#terms,
	#privacy,
	#cookies {
		max-width: 630px;
	}
	
}

@media screen and (min-width: 1200px) {
	
	
	
}
@media screen and (min-width: 1400px) {
	
		
}


.autoclose {
	visibility: none;
}
#index .autclose {
	visibility: 100%;
}

#register #map-content,
#login #map-content {
	display: none;
}


